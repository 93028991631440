<template>
  <ul>
    <li><strong>Fecha: </strong>{{ item.attributes.date }}</li>
    <li><strong>En movimiento: </strong>{{ item.attributes.motion ? 'Si' : 'No' }}</li>
    <li><strong>Velocidad: </strong>{{ item.speed | speed }}</li>
    <li><strong>Velocidad OBD: </strong>{{ item.attributes.obdSpeed }}</li>
    <li><strong>Energia: </strong>{{ item.attributes.power }}</li>
    <li><strong>Bateria</strong>{{ item.attributes.battery }}</li>
    <li><strong>RPM: </strong>{{ item.attributes.rpm }}</li>
    <li><strong>adc1: </strong>{{ item.attributes.adc1 }}</li>
    <li><strong>adc2: </strong>{{ item.attributes.adc2 }}</li>
    <li><strong>Distancia Recorrida: </strong>{{ item.attributes.distance }}</li>
    <li><strong>Señal: </strong>{{ item.attributes.antenna }}</li>
    <li><strong>Nivel de Combustible: </strong>{{ item.attributes.fuel }}</li>
    <li><strong>Combustible Consumido: </strong>{{ item.attributes.fuelUsed }}</li>
    <li><strong>Consumo de Combustible: </strong>{{ item.attributes.fuelConsumption }}</li>
    <li><strong>Temperatura de anticongelante: </strong>{{ item.attributes.coolantTemp }}</li>
    <li><strong>Odometro: </strong>{{ item.attributes.odometer }}</li>
    <li><strong>Temperatura: </strong>{{ item.attributes.temp }}</li>
    <li><strong>Aceleración: </strong>{{ item.attributes.aceleration }}</li>
  </ul>
</template>

<script>
export default {
  name: 'Telemetrias',
  data() {
    return {
      item: {},
    };
  },
};
</script>

<style scoped>
/* Estilos específicos del componente */
</style>
